import { gql } from '@apollo/client';
import { Account } from 'interfaces/Account';
import { Equipment } from 'interfaces/Equipment';
import { Organization } from 'interfaces/Organization';
import { WorkOrder } from 'interfaces/WorkOrder';
import { Invoice, Task, TaskEquipment, TaskLabor, TaskMaterial } from 'interfaces/Worklog';
import { TaskFixedCostItem } from 'interfaces/Worklog/TaskFixedCostItem';

export interface GetOrganizationEquipmentResponse {
  organization: {
    equipment: Pick<Equipment, 'id' | 'name' | 'isActive' | 'rate'>[];
  };
}

export const GET_ORGANIZATION_EQUIPMENT = gql`
  query GetOrganizationEquipment($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      equipment {
        id
        name
        isActive
        rate {
          primary
        }
      }
    }
  }
`;

export interface TaskDisplay
  extends Pick<
    Task,
    | 'id'
    | 'name'
    | 'taskDate'
    | 'laborCost'
    | 'equipmentCost'
    | 'materialCost'
    | 'totalCost'
    | 'fixedCostItemCost'
  > {
  workorder: Pick<WorkOrder, 'id' | 'title' | 'location'>;
  laborItems: Pick<TaskLabor, 'account' | 'rate' | 'hours' | 'itemCost'>[];
  equipmentItems: Pick<TaskEquipment, 'equipment' | 'rate' | 'hours' | 'itemCost' | 'mileage'>[];
  materialItems: Pick<
    TaskMaterial,
    'id' | 'name' | 'unitCost' | 'quantity' | 'itemCost' | 'referenceNumber'
  >[];
  fixedCostItems: Pick<
    TaskFixedCostItem,
    'id' | 'name' | 'quantity' | 'itemCost' | 'unit' | 'unitCost'
  >[];
  invoice: Pick<Invoice, 'id' | 'invoiceNumber' | 'url'>;
}

/** Task fields required to display in the details page */
const taskDisplayFragment = {
  taskDisplay: gql`
    fragment TaskDisplay on WorklogTask {
      workorder {
        id
        title
        location {
          address
        }
      }
      id
      name
      taskDate
      laborItems {
        account {
          id
          name
        }
        rate
        hours
        itemCost
      }
      laborCost
      equipmentItems {
        equipment {
          id
          name
        }
        rate
        hours
        itemCost
        mileage
      }
      equipmentCost
      materialItems {
        id
        name
        unitCost
        quantity
        itemCost
        referenceNumber
      }
      materialCost
      fixedCostItems {
        id
        name
        unitCost
        quantity
        itemCost
        unit
      }
      fixedCostItemCount
      fixedCostItemCost
      totalCost
      invoice {
        id
        invoiceNumber
        url
      }
    }
  `,
};

export interface GetTaskResponse {
  worklogTask: TaskDisplay;
}

export const GET_TASK = gql`
  query GetWorklogTask($taskId: ID!) {
    worklogTask(id: $taskId) {
      ...TaskDisplay
    }
  }
  ${taskDisplayFragment.taskDisplay}
`;

export type InvoiceSummary = Pick<
  Invoice,
  'id' | 'invoiceDate' | 'invoiceNumber' | 'description' | 'amountDue' | 'url'
>;

// Invoice fields required to display in the list page
const invoiceSummaryFragment = {
  invoiceSummary: gql`
    fragment InvoiceSummary on WorklogInvoice {
      id
      invoiceDate
      invoiceNumber
      description
      amountDue
      url
    }
  `,
};

export interface GetAfterCreateInvoiceUpdates {
  workorder: Pick<WorkOrder, 'id'> & {
    invoices: InvoiceSummary[];
    worklog: Pick<Task, 'id'> & { invoice: Pick<Invoice, 'id' | 'invoiceNumber'> };
  };
}

/** Query for all data that will change after an invoice is created */
export const GET_AFTER_CREATE_INVOICE_UPDATES = gql`
  query GetWorkorderInvoices($workorderId: ID!) {
    workorder(id: $workorderId) {
      id
      invoices {
        ...InvoiceSummary
      }
      worklog {
        id
        invoice {
          id
          invoiceNumber
        }
      }
    }
  }
  ${invoiceSummaryFragment.invoiceSummary}
`;

export type TaskSummary = Pick<
  Task,
  | 'id'
  | 'name'
  | 'taskDate'
  | 'laborCount'
  | 'equipmentCount'
  | 'materialCount'
  | 'totalCost'
  | 'fixedCostItemCount'
>;

// Task fields required to display in the list page
const taskSummaryFragment = {
  taskSummary: gql`
    fragment TaskSummary on WorklogTask {
      id
      name
      taskDate
      laborCount
      equipmentCount
      materialCount
      fixedCostItemCount
      totalCost
    }
  `,
};

export interface GetWorkorderWorklogResponse {
  workorder: {
    worklog: TaskSummary[];
  };
}

export const GET_WORKORDER_WORKLOG = gql`
  query GetWorkorderWorklog($workorderId: ID!) {
    workorder(id: $workorderId) {
      id
      worklog {
        ...TaskSummary
      }
    }
  }
  ${taskSummaryFragment.taskSummary}
`;

export interface GetOrganizationInvoiceTemplatesResponse {
  organization: Pick<Organization, 'id' | 'invoiceTemplates'>;
}

export const GET_ORGANIZATION_INVOICE_TEMPLATES = gql`
  query GetOrganizationInvoiceTemplates($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      invoiceTemplates {
        id
      }
    }
  }
`;

export interface CreateInvoiceInput {
  workorderId: string;
  templateId: string;
  invoiceNumber: string;
  invoiceDate: number;
  description: string;
  amountDue: number;
  recipient?: {
    name: string;
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    email?: string;
  };
  taskIds: string[];
}

export interface CreateInvoiceResponse {
  createWorklogInvoice: { invoice: InvoiceSummary & { workorder: { id: string } } };
}

export const CREATE_INVOICE = gql`
  mutation CreateWorklogInvoice(
    $workorderId: ID!
    $templateId: ID!
    $invoiceNumber: String!
    $invoiceDate: Float!
    $description: String!
    $amountDue: Float!
    $recipient: CreateWorklogInvoiceRecipientInput!
    $taskIds: [ID!]
  ) {
    createWorklogInvoice(
      input: {
        workorderId: $workorderId
        templateId: $templateId
        invoiceNumber: $invoiceNumber
        invoiceDate: $invoiceDate
        description: $description
        amountDue: $amountDue
        recipient: $recipient
        taskIds: $taskIds
      }
    ) {
      invoice {
        workorder {
          id
        }
        ...InvoiceSummary
      }
    }
  }
  ${invoiceSummaryFragment.invoiceSummary}
`;

export interface CreateTaskInput {
  workorderId: string;
  name: string;
  taskDate: number;
}

export interface CreateTaskResponse {
  createWorklogTask: { task: TaskSummary & { workorder: { id: string } } };
}

export const CREATE_TASK = gql`
  mutation CreateWorklogTask($workorderId: ID!, $name: String!, $taskDate: Float!) {
    createWorklogTask(input: { workorderId: $workorderId, name: $name, taskDate: $taskDate }) {
      task {
        workorder {
          id
        }
        ...TaskSummary
      }
    }
  }
  ${taskSummaryFragment.taskSummary}
`;

export interface RemoveTaskInput {
  taskId: string;
}

export interface RemoveTaskResponse {
  removeWorklogTask: { task: Pick<Task, 'id'> };
}

export const REMOVE_TASK = gql`
  mutation RemoveWorklogTask($taskId: ID!) {
    removeWorklogTask(input: { taskId: $taskId }) {
      task {
        id
      }
    }
  }
`;

export interface GetAvailableAccountsVariables {
  organizationId: string;
}

export interface GetAvailableAccountsResponse {
  accounts: {
    nodes: Pick<Account, 'id' | 'name' | 'rate' | 'status'>[];
  };
}

export const GET_AVAILABLE_ACCOUNTS = gql`
  query GetAvailableAccounts($organizationId: ID!) {
    accounts(query: { organizationId: $organizationId }) {
      nodes {
        id
        name
        rate
        status
      }
    }
  }
`;

export interface WorklogTaskLaborInput {
  accountId: string;
  rate: number;
  hours: number;
}
export interface AddMultipleTaskLaborInput {
  taskId: string;
  labors: WorklogTaskLaborInput[];
}

export const ADD_MULTIPLE_TASK_LABOR = gql`
  mutation AddMultipleWorklogTaskLabor($taskId: ID!, $labors: [WorklogTaskLaborInput!]) {
    addMultipleWorklogTaskLabor(input: { taskId: $taskId, labors: $labors }) {
      task {
        id
        laborItems {
          account {
            id
            name
          }
          rate
          hours
          itemCost
        }
        laborCost
        totalCost
      }
    }
  }
`;

export interface RemoveTaskLaborInput {
  taskId: string;
  accountId: string;
}

export const REMOVE_TASK_LABOR = gql`
  mutation RemoveWorklogTaskLabor($taskId: ID!, $accountId: ID!) {
    removeWorklogTaskLabor(input: { taskId: $taskId, accountId: $accountId }) {
      task {
        id
        laborItems {
          account {
            id
            name
          }
          rate
          hours
          itemCost
        }
        laborCost
        totalCost
      }
    }
  }
`;

export interface WorklogTaskEquipmentInput {
  equipmentId: string;
  rate: number;
  hours: number;
  mileage?: number;
}

export interface AddMultipleTaskEquipmentInput {
  taskId: string;
  equipments: WorklogTaskEquipmentInput[];
}

export const ADD_MULTIPLE_TASK_EQUIPMENT = gql`
  mutation AddMultipleWorklogTaskEquipment(
    $taskId: ID!
    $equipments: [WorklogTaskEquipmentInput!]
  ) {
    addMultipleWorklogTaskEquipment(input: { taskId: $taskId, equipments: $equipments }) {
      task {
        id
        equipmentItems {
          equipment {
            id
            name
          }
          rate
          hours
          itemCost
          mileage
        }
        equipmentCost
        totalCost
      }
    }
  }
`;

export interface RemoveTaskEquipmentInput {
  taskId: string;
  equipmentId: string;
}

export const REMOVE_TASK_EQUIPMENT = gql`
  mutation RemoveWorklogTaskEquipment($taskId: ID!, $equipmentId: ID!) {
    removeWorklogTaskEquipment(input: { taskId: $taskId, equipmentId: $equipmentId }) {
      task {
        id
        equipmentItems {
          equipment {
            id
            name
          }
          rate
          hours
          itemCost
          mileage
        }
        equipmentCost
        totalCost
      }
    }
  }
`;

export interface WorklogTaskMaterialInput {
  name: string;
  unitCost: number;
  quantity: number;
  referenceNumber?: string;
}

export interface AddMultipleTaskMaterialInput {
  taskId: string;
  materials: WorklogTaskMaterialInput[];
}

export const ADD_MULTIPLE_TASK_MATERIAL = gql`
  mutation AddMultipleWorklogTaskMaterial($taskId: ID!, $materials: [WorklogTaskMaterialInput!]) {
    addMultipleWorklogTaskMaterial(input: { taskId: $taskId, materials: $materials }) {
      task {
        id
        materialItems {
          id
          name
          unitCost
          quantity
          referenceNumber
          itemCost
        }
        materialCost
        totalCost
      }
    }
  }
`;

export interface RemoveTaskMaterialInput {
  taskId: string;
  materialId: string;
}

export const REMOVE_TASK_MATERIAL = gql`
  mutation RemoveWorklogTaskMaterial($taskId: ID!, $materialId: ID!) {
    removeWorklogTaskMaterial(input: { taskId: $taskId, materialId: $materialId }) {
      task {
        id
        materialItems {
          id
          name
          unitCost
          quantity
          referenceNumber
          itemCost
        }
        materialCost
        totalCost
      }
    }
  }
`;

export interface EditWorklogTaskInput {
  taskId: string;
  name: string;
  taskDate: number;
}

export const EDIT_TASK = gql`
  mutation EditWorklogTask($taskId: ID!, $name: String!, $taskDate: Float!) {
    editWorklogTask(input: { taskId: $taskId, name: $name, taskDate: $taskDate }) {
      task {
        id
        name
        taskDate
      }
    }
  }
`;

export interface WorklogTaskFixedCostItemInput {
  name: string;
  unitCost: number;
  quantity: number;
  unit?: string;
}

export interface AddMultipleTaskFixedCostItemInput {
  taskId: string;
  fixedCostItems: WorklogTaskFixedCostItemInput[];
}

export const ADD_MULTIPLE_TASK_FIXED_COST_ITEM = gql`
  mutation AddMultipleWorklogTaskFixedCostItem(
    $taskId: ID!
    $fixedCostItems: [WorklogTaskFixedCostItemInput!]
  ) {
    addMultipleWorklogTaskFixedCostItem(
      input: { taskId: $taskId, fixedCostItems: $fixedCostItems }
    ) {
      task {
        id
        fixedCostItems {
          id
          name
          unitCost
          quantity
          itemCost
          unit
        }
        fixedCostItemCount
        fixedCostItemCost
        totalCost
      }
    }
  }
`;

export interface RemoveTaskFixedCostItemInput {
  taskId: string;
  fixedCostItemId: string;
}

export interface RemoveTaskFixedCostItemResponse {
  removeWorklogTaskFixedCostItem: {
    task: {
      id: string;
      fixedCostItems: Pick<
        TaskFixedCostItem,
        'id' | 'name' | 'unitCost' | 'quantity' | 'itemCost' | 'unit'
      >[];
      fixedCostItemCount: number;
      fixedCostItemCost: number;
      totalCost: number;
    };
  };
}

export const REMOVE_TASK_FIXED_COST_ITEM = gql`
  mutation RemoveWorklogTaskFixedCostItem($taskId: ID!, $fixedCostItemId: ID!) {
    removeWorklogTaskFixedCostItem(input: { taskId: $taskId, fixedCostItemId: $fixedCostItemId }) {
      task {
        id
        fixedCostItems {
          id
          name
          unitCost
          quantity
          itemCost
          unit
        }
        fixedCostItemCount
        fixedCostItemCost
        totalCost
      }
    }
  }
`;
