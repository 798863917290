import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import AddNewItemButton from 'components/AddNewItemButton';
import UnsafeArea from 'components/UnsafeArea';
import { closeOutline as closeIcon } from 'ionicons/icons';
import isEmpty from 'lodash.isempty';
import React, { PropsWithChildren, RefObject, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { v4 as uuidv4 } from 'uuid';

import MaterialForm from './MaterialForm';

const useStyles = createUseStyles({
  root: {
    '--inner-padding-start': '5px',
    '--inner-padding-end': '0px',
    fontSize: '0.875rem',
    lineHeight: 1.2,

    '& ion-label p': {
      lineHeight: 1.2,
    },
  },
});

export interface MaterialModalProps {
  label?: string;
  pageRef?: RefObject<HTMLElement>;
  onSubmit: (
    data: {
      name: string;
      unitCost: number;
      quantity: number;
      referenceNumber?: string;
    }[]
  ) => Promise<void>;
}

export interface MaterialProps {
  id: string;
  name: string | undefined;
  unitCost: string | undefined;
  quantity: string | undefined;
  referenceNumber?: string | undefined;
}

const MaterialModal = ({
  label = 'Add Materials',
  pageRef,
  onSubmit,
}: PropsWithChildren<MaterialModalProps>) => {
  const classes = useStyles();
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [materials, setMaterials] = useState<MaterialProps[]>([
    {
      id: uuidv4(),
      name: undefined,
      unitCost: undefined,
      quantity: undefined,
      referenceNumber: undefined,
    },
  ]);

  const submit = async () => {
    if (isEmpty(materials)) return;

    try {
      await onSubmit(
        materials.map((item) => ({
          name: item.name as string,
          unitCost: parseFloat(item.unitCost as string),
          quantity: parseFloat(item.quantity as string),
          referenceNumber: item.referenceNumber,
        }))
      );
      setIsModalOpen(false);
    } catch (_err) {
      // NOOP
    }
  };

  const addMaterial = () => {
    setMaterials([
      ...materials,
      {
        id: uuidv4(),
        name: undefined,
        unitCost: undefined,
        quantity: undefined,
        referenceNumber: undefined,
      },
    ]);
  };

  const remove = (props: MaterialProps) => {
    setMaterials(materials?.filter((item) => item.id !== props.id));
  };

  const updateForm = (props: MaterialProps) => {
    setMaterials(materials?.map((item) => (item.id === props.id ? props : item)));
  };

  const updateUnitCostAll = (props: MaterialProps) => {
    setMaterials(
      materials?.map((item) =>
        item.id === props.id ? props : { ...item, unitCost: props.unitCost }
      )
    );
  };

  const updateUnitCostEmpty = (props: MaterialProps) => {
    setMaterials(() => {
      return materials?.map((item) => {
        if (item.id === props.id) {
          return props;
        } else {
          if (item.unitCost === undefined) {
            return { ...item, unitCost: props.unitCost };
          }
          return item;
        }
      });
    });
  };

  const updateQuantityAll = (props: MaterialProps) => {
    setMaterials(
      materials?.map((item) =>
        item.id === props.id ? props : { ...item, quantity: props.quantity }
      )
    );
  };

  const updateQuantityEmpty = (props: MaterialProps) => {
    setMaterials(() => {
      return materials?.map((item) => {
        if (item.id === props.id) {
          return props;
        } else {
          if (item.quantity === undefined) {
            return { ...item, quantity: props.quantity };
          }
          return item;
        }
      });
    });
  };

  const canSubmit = !materials.some(
    (item) => item.name === undefined || item.quantity === undefined || item.unitCost === undefined
  );

  return (
    <>
      <AddNewItemButton title={label} onClick={() => setIsModalOpen(true)} />
      <IonModal
        onDidDismiss={() => {
          setIsModalOpen(false);
          setMaterials([
            {
              id: uuidv4(),
              name: undefined,
              unitCost: undefined,
              quantity: undefined,
              referenceNumber: undefined,
            },
          ]);
        }}
        isOpen={isModalOpen}
        swipeToClose={true}
        presentingElement={pageRef?.current || undefined}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setIsModalOpen(false)}>
                <IonIcon slot="icon-only" icon={closeIcon} />
              </IonButton>
            </IonButtons>
            <IonTitle>{label}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent ref={contentRef}>
          <IonList lines="full" className="ion-no-padding" style={{ width: '100%' }}>
            {Boolean(materials.length) && (
              <IonItem lines="full" detail={false} className={classes.root}>
                <IonGrid style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>
                  <IonRow className="ion-align-items-center">
                    <IonCol size="4" />
                    <IonCol className="ion-text-center" size="2">
                      <IonLabel style={{ whiteSpace: 'normal', lineHeight: 1.5 }}>
                        {/* ion-text-wrap changes font-size in iOS, using inline style instead */}
                        Unit Cost
                      </IonLabel>
                    </IonCol>
                    <IonCol className="ion-text-center" size="2">
                      <IonLabel>Quantity</IonLabel>
                    </IonCol>
                    <IonCol className="ion-text-center" size="4">
                      <IonLabel>Ref #</IonLabel>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonButtons slot="end" style={{ visibility: 'hidden' }}>
                  <IonButton>
                    <IonIcon slot="icon-only" icon={closeIcon} size="small" />
                  </IonButton>
                </IonButtons>
              </IonItem>
            )}
            {materials?.map((selected, index) => (
              <MaterialForm
                material={selected}
                handleRemove={remove}
                handleUpdate={updateForm}
                handleUpdateUnitCostAll={updateUnitCostAll}
                handleUpdateUnitCostEmpty={updateUnitCostEmpty}
                handleUpdateQuantityAll={updateQuantityAll}
                handleUpdateQuantityEmpty={updateQuantityEmpty}
                key={index}
              />
            ))}
            <AddNewItemButton title={'New Line'} onClick={addMaterial} />
          </IonList>
        </IonContent>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton fill="clear" disabled={!canSubmit} onClick={submit}>
              Submit
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <UnsafeArea position="bottom" />
      </IonModal>
    </>
  );
};

export default MaterialModal;
