import { InputChangeEventDetail } from '@ionic/core/components';
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonList,
  IonPopover,
  IonRow,
} from '@ionic/react';
import { ellipsisVertical as menuIcon, trashBinOutline as deleteIcon } from 'ionicons/icons';
import React, { useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';

import { FixedCostItemProps } from './FixedCostItemModal';

const useStyles = createUseStyles({
  root: {
    '--inner-padding-start': '5px',
    '--inner-padding-end': '0px',
    fontSize: '0.875rem',
    lineHeight: 1.2,

    '& ion-label p': {
      lineHeight: 1.2,
    },
  },
});

type FixedCostItemFormProps = {
  fixedCostItem: FixedCostItemProps;
  handleRemove: (props: FixedCostItemProps) => void;
  handleUpdate: (props: FixedCostItemProps) => void;
  handleUpdateUnitCostAll: (props: FixedCostItemProps) => void;
  handleUpdateUnitCostEmpty: (props: FixedCostItemProps) => void;
  handleUpdateQuantityAll: (props: FixedCostItemProps) => void;
  handleUpdateQuantityEmpty: (props: FixedCostItemProps) => void;
};

const FixedCostItemForm: React.FC<FixedCostItemFormProps> = ({
  fixedCostItem,
  handleRemove,
  handleUpdate,
  handleUpdateUnitCostAll,
  handleUpdateUnitCostEmpty,
  handleUpdateQuantityAll,
  handleUpdateQuantityEmpty,
}) => {
  const classes = useStyles();
  const itemSlidingRef = useRef<HTMLIonItemSlidingElement>(null);
  const [popoverState, setPopoverState] = React.useState<{
    showPopup: boolean;
    event: CustomEvent<InputChangeEventDetail> | undefined;
  }>({
    showPopup: false,
    event: undefined,
  });

  const closePopover = () => {
    setPopoverState({
      showPopup: false,
      event: undefined,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <IonItemSliding ref={itemSlidingRef}>
      <IonItemOptions side="start">
        <IonItemOption color="danger" onClick={() => handleRemove(fixedCostItem)}>
          <IonIcon slot="icon-only" icon={deleteIcon} />
        </IonItemOption>
      </IonItemOptions>
      <IonItem lines="full" detail={false} className={classes.root}>
        <IonGrid style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>
          <IonRow className="ion-align-items-center ion-no-padding" style={{ width: '100%' }}>
            <IonCol size="4" className="ion-text-left">
              <IonInput
                value={fixedCostItem.name}
                type="text"
                placeholder="Name"
                onIonChange={(e) =>
                  handleUpdate({
                    ...fixedCostItem,
                    name: e.detail.value ? e.detail.value : undefined,
                  })
                }
              />
            </IonCol>
            <IonCol className="ion-text-center" size="2">
              <IonInput
                value={fixedCostItem.unitCost}
                type="number"
                step="1"
                inputMode="decimal"
                placeholder="Cost"
                onIonChange={(e) =>
                  handleUpdate({
                    ...fixedCostItem,
                    unitCost: e.detail.value ? e.detail.value : undefined,
                  })
                }
              />
            </IonCol>
            <IonCol className="ion-text-center" size="2">
              <IonInput
                value={fixedCostItem.quantity}
                type="number"
                step="1"
                inputMode="decimal"
                placeholder="Quantity"
                onIonChange={(e) =>
                  handleUpdate({
                    ...fixedCostItem,
                    quantity: e.detail.value ? e.detail.value : undefined,
                  })
                }
              />
            </IonCol>
            <IonCol className="ion-text-center" size="2">
              <IonInput
                value={fixedCostItem.unit}
                type="text"
                step="1"
                placeholder="Unit"
                onIonChange={(e) =>
                  handleUpdate({
                    ...fixedCostItem,
                    unit: e.detail.value ? e.detail.value : undefined,
                  })
                }
              />
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonButtons slot="end">
          <IonButton
            fill="clear"
            tabIndex={-1}
            onClick={(e: any) => setPopoverState({ showPopup: true, event: e })}
          >
            <IonIcon slot="icon-only" color="primary" icon={menuIcon} size="small" />
            <IonPopover
              mode="md"
              showBackdrop={false}
              keyboardClose={false}
              event={popoverState.event}
              isOpen={popoverState.showPopup}
              onDidDismiss={() => closePopover()}
            >
              <IonContent>
                <IonList style={{ padding: 0 }}>
                  <IonItem
                    button={true}
                    disabled={!fixedCostItem.unitCost}
                    onClick={() => {
                      closePopover();
                      handleUpdateUnitCostEmpty(fixedCostItem);
                    }}
                  >
                    Apply cost to all empty
                  </IonItem>
                  <IonItem
                    button={true}
                    disabled={!fixedCostItem.unitCost}
                    onClick={() => {
                      handleUpdateUnitCostAll(fixedCostItem);
                      closePopover();
                    }}
                  >
                    Apply cost to all
                  </IonItem>
                  <IonItem
                    button={true}
                    disabled={!fixedCostItem.quantity}
                    onClick={() => {
                      handleUpdateQuantityEmpty(fixedCostItem);
                      closePopover();
                    }}
                  >
                    Apply units to all empty
                  </IonItem>
                  <IonItem
                    button={true}
                    disabled={!fixedCostItem.quantity}
                    onClick={() => {
                      handleUpdateQuantityAll(fixedCostItem);
                      closePopover();
                    }}
                  >
                    Apply units to all
                  </IonItem>
                </IonList>
              </IonContent>
            </IonPopover>
          </IonButton>
        </IonButtons>
      </IonItem>
    </IonItemSliding>
  );
};

export default FixedCostItemForm;
