import { IonToast, ToastButton } from '@ionic/react';
import { useMessages } from 'hooks/useMessages';
import { Message } from 'interfaces/Message';
import { closeOutline as closeIcon } from 'ionicons/icons';
import React from 'react';
import { createUseStyles } from 'react-jss';

const toastButtons: ToastButton[] = [
  {
    role: 'cancel',
    icon: closeIcon,
  },
];

const useStyles = createUseStyles({
  offsetBottomToast: { transform: 'translateY(-56px)' }, // Offset up to avoid bottom content, like comment bar
});

const MessagesDisplay: React.FC = () => {
  const classes = useStyles();
  const { messages, clearMessage, display } = useMessages();

  return (
    <>
      {messages.map((message: Message) => (
        <IonToast
          key={message.timestamp}
          color={message.color || 'toast'}
          isOpen={display}
          onDidDismiss={() => clearMessage(message.timestamp)}
          message={message.content}
          position="bottom"
          mode="md"
          translucent={true}
          buttons={toastButtons}
          duration={10000}
          cssClass={classes.offsetBottomToast}
        />
      ))}
    </>
  );
};

export default MessagesDisplay;
