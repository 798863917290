import {
  IonActionSheet,
  IonAlert,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonRow,
  IonText,
  isPlatform,
} from '@ionic/react';
import { trashBinOutline as deleteIcon } from 'ionicons/icons';
import React, { useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { formatCurrency } from 'utils/formatCurrency';

const isMobile = isPlatform('mobile');

const useStyles = createUseStyles({
  root: {
    fontSize: '0.875rem',
    lineHeight: 1.2,

    '& ion-label p': {
      lineHeight: 1.2,
    },
  },
});

export interface TableItemProps {
  name: string;
  rate: number;
  quantity: number;
  total: number;
  note?: string;
  unit?: string;
  lines?: 'full' | 'inset' | 'none';
  routerLink?: string;
  /** User is allowed to delete this item. Default: false */
  deleteAllowed?: boolean;
  /**
   * Message to display when asking user if they want to confirm deletion of an item
   * ex. "Would you like to permanently delete this labor item?"
   */
  deleteHeader?: string;
  /* Callback to delete table item */
  onDelete?: () => void;
}

/** Individual labor, material, equipment line item */
const TableItem: React.FC<TableItemProps> = ({
  name,
  rate,
  quantity,
  total,
  note,
  unit,
  lines,
  routerLink,
  deleteAllowed = false,
  deleteHeader = '',
  onDelete,
}) => {
  const classes = useStyles();

  const itemSlidingRef = useRef<HTMLIonItemSlidingElement>(null);

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const deleteDismiss = () => {
    if (itemSlidingRef.current) {
      itemSlidingRef.current.close();
    }
    setShowConfirmDelete(false);
  };

  const deleteButtons = [
    { text: 'Cancel', role: 'cancel' },
    { text: 'Delete', role: 'destructive', handler: onDelete },
  ];

  return (
    <>
      <IonItemSliding ref={itemSlidingRef} disabled={!deleteAllowed}>
        <IonItemOptions side="start">
          {deleteAllowed && (
            <IonItemOption color="danger" onClick={() => setShowConfirmDelete(true)}>
              <IonIcon slot="icon-only" icon={deleteIcon} />
            </IonItemOption>
          )}
        </IonItemOptions>
        <IonItem className={classes.root} lines={lines} routerLink={routerLink} detail={false}>
          <IonGrid style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>
            <IonRow className="ion-align-items-center">
              <IonCol style={{ paddingLeft: 0 }} size="4">
                <IonLabel>{name}</IonLabel>
              </IonCol>
              <IonCol className="ion-text-center" size={unit ? '2' : '3'}>
                <IonLabel>{formatCurrency(rate)}</IonLabel>
              </IonCol>
              <IonCol className="ion-text-center" size="2">
                <IonLabel>{quantity}</IonLabel>
              </IonCol>
              {unit && (
                <IonCol className="ion-text-center" size="2">
                  <IonLabel>{unit}</IonLabel>
                </IonCol>
              )}
              <IonCol
                className="ion-text-right"
                size={unit ? '2' : '3'}
                style={{ paddingRight: 0 }}
              >
                <IonLabel>{formatCurrency(total)}</IonLabel>
              </IonCol>
            </IonRow>
            {note && (
              <IonRow>
                <IonCol size="12" style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <IonLabel>
                    <p>
                      <IonText color="medium">{note}</IonText>
                    </p>
                  </IonLabel>
                </IonCol>
              </IonRow>
            )}
          </IonGrid>
        </IonItem>
      </IonItemSliding>
      <IonAlert
        isOpen={showConfirmDelete && !isMobile}
        onDidDismiss={deleteDismiss}
        header="Delete"
        message={deleteHeader}
        buttons={deleteButtons}
      />
      <IonActionSheet
        isOpen={showConfirmDelete && isMobile}
        header={deleteHeader}
        onDidDismiss={deleteDismiss}
        buttons={deleteButtons}
      />
    </>
  );
};

export default TableItem;
