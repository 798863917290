import { ToggleChangeEventDetail } from '@ionic/core/components';
import {
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonLoading,
  IonToggle,
} from '@ionic/react';
import { AccountAction } from 'interfaces/AccountAction';
import { keyOutline as permissionsIcon } from 'ionicons/icons';
import React, { useState } from 'react';

const actionGroups: { name: string; subtitle: string; actions: AccountAction[] }[] = [
  {
    name: 'View User Accounts',
    subtitle: 'View user accounts',
    actions: [AccountAction.GetAccount, AccountAction.ListAccounts],
  },
  {
    name: 'Chat',
    subtitle: 'View, post, edit, and delete chat messages',
    actions: [
      AccountAction.GetChatChannel,
      AccountAction.PostChatMessage,
      AccountAction.EditChatMessage,
      AccountAction.DeleteChatMessage,
    ],
  },
  {
    name: 'Manage User Accounts',
    subtitle: 'Invite new users and assign user permissions',
    actions: [
      AccountAction.GetAccount,
      AccountAction.ListAccounts,
      AccountAction.CreateAccount,
      AccountAction.CreateVirtualAccount,
      AccountAction.AttachPermissions,
      AccountAction.DetachPermissions,
      AccountAction.SetAccountRate,
      AccountAction.TransferTeam,
      AccountAction.DeactivateAccount,
      AccountAction.ReactivateAccount,
      AccountAction.ReinviteAccount,
      AccountAction.MuteSecondaryNotifications,
      AccountAction.UnmuteSecondaryNotifications,
    ],
  },
  {
    name: 'View Work Orders',
    subtitle: 'View work orders',
    actions: [AccountAction.ListWorkOrders, AccountAction.GetWorkOrder],
  },
  {
    name: 'Create Work Orders',
    subtitle: 'Create new work orders',
    actions: [
      AccountAction.ListWorkOrders,
      AccountAction.GetWorkOrder,
      AccountAction.CreateWorkOrder,
      AccountAction.AssignWorkOrderCategory,
      AccountAction.AssignWorkOrderLocation,
      AccountAction.AssignWorkOrderAssignee,
      AccountAction.AssignWorkOrderTeam,
      AccountAction.AssignWorkOrderReporter,
      AccountAction.ListAccounts,
      AccountAction.AddWorkOrderTag,
      AccountAction.RemoveWorkOrderTag,
      AccountAction.EditWorkOrderDescription,
      AccountAction.TagWorkOrderAssets,
    ],
  },
  {
    name: 'Assign Work Orders',
    subtitle:
      'Assign work orders to teams or assignees and update the work order resident reporter',
    actions: [
      AccountAction.AssignWorkOrderAssignee,
      AccountAction.AssignWorkOrderTeam,
      AccountAction.AssignWorkOrderReporter,
      AccountAction.ListAccounts,
    ],
  },
  {
    name: 'Update Work Order Status',
    subtitle: 'Assign start, on hold, deny, cancel, and complete statuses to work orders',
    actions: [
      AccountAction.StartWorkOrder,
      AccountAction.DenyWorkOrder,
      AccountAction.CancelWorkOrder,
      AccountAction.CompleteWorkOrder,
      AccountAction.ReopenWorkOrder,
      AccountAction.HoldWorkOrder,
    ],
  },
  {
    name: 'Document Work Orders',
    subtitle: 'Attach photos and files and leave comments on work orders',
    actions: [
      AccountAction.AddWorkOrderComment,
      AccountAction.EditWorkOrderComment,
      AccountAction.DeleteWorkOrderComment,
      AccountAction.AttachWorkOrderAttachment,
      AccountAction.DetachWorkOrderAttachment,
    ],
  },
  {
    name: 'Create Work Order Worklogs',
    subtitle:
      'Create tasks to organize labor, equipment, materials, and fixed cost items used to perform work',
    actions: [
      AccountAction.CreateWorklogTask,
      AccountAction.EditWorklogTask,
      AccountAction.RemoveWorklogTask,
      AccountAction.AddMultipleWorklogTaskEquipment,
      AccountAction.AddWorklogTaskEquipment,
      AccountAction.AddMultipleWorklogTaskLabor,
      AccountAction.AddWorklogTaskLabor,
      AccountAction.AddMultipleWorklogTaskMaterial,
      AccountAction.AddWorklogTaskMaterial,
      AccountAction.AddWorklogTaskFixedCostItem,
      AccountAction.AddMultipleWorklogTaskFixedCostItem,
      AccountAction.RemoveWorklogTaskEquipment,
      AccountAction.RemoveWorklogTaskLabor,
      AccountAction.RemoveWorklogTaskMaterial,
      AccountAction.RemoveWorklogTaskFixedCostItem,
      AccountAction.ListAccounts,
    ],
  },
  {
    name: 'Create Invoices',
    subtitle: 'Create invoices from work orders',
    actions: [AccountAction.CreateWorklogInvoice],
  },
  {
    name: 'Manage Equipment',
    subtitle: 'Create, update, and deactivate equipment and log equipment maintenance',
    actions: [
      AccountAction.CreateEquipment,
      AccountAction.DeactivateEquipment,
      AccountAction.ActivateEquipment,
      AccountAction.SetEquipmentName,
      AccountAction.SetEquipmentRate,
      AccountAction.SetEquipmentVehicle,
      AccountAction.LogEquipmentMaintenance,
      AccountAction.CorrectEquipmentMaintenanceEntry,
      AccountAction.DeleteEquipmentMaintenanceEntry,
    ],
  },
  {
    name: 'Reassign Work Order Organizations',
    subtitle: 'Move work orders to a new organization',
    actions: [AccountAction.ListOrganizations, AccountAction.AssignWorkOrderOrganization],
  },
  // Not currently used
  // AccountAction.CreateCategory,
  // AccountAction.EnableCategory,
  // AccountAction.DisableCategory,
  // AccountAction.CreateOrganization,
  // AccountAction.CreateTeam,
  // AccountAction.DefineGeography,
  // AccountAction.GetOrganization
];

export interface ActionManagerProps {
  /** Actions being managed */
  selected: AccountAction[];
  /** Actions allowed to be used in the manager (ie. current user has permission to manage) */
  allowed: AccountAction[];
  disabled?: boolean;
  onAttachPermission: (actions: AccountAction[]) => Promise<void>;
  onDetachPermission: (actions: AccountAction[]) => Promise<void>;
}

const ActionManager: React.FC<ActionManagerProps> = ({
  selected = [],
  allowed = [],
  disabled = false,
  onAttachPermission,
  onDetachPermission,
}) => {
  const [current, setCurrent] = useState<AccountAction[]>(selected);
  const [mutating, setMutating] = useState<boolean>(false);

  const onToggle = async (e: CustomEvent<ToggleChangeEventDetail>, actions: AccountAction[]) => {
    const checked = e.detail.checked;

    // To prevent multiple firing
    if (mutating) return;

    setMutating(true);

    if (checked) {
      setCurrent([...new Set([...current, ...actions])]);
      await onAttachPermission(actions);
    } else {
      const updatedList = current.filter((c) => !actions.includes(c));
      setCurrent(updatedList);
      await onDetachPermission(actions);
    }

    setMutating(false);
  };

  const availableActionGroups = actionGroups.filter((g) =>
    g.actions.every((p) => allowed.includes(p))
  );

  const isChecked = (actions: AccountAction[]): boolean =>
    actions.every((a) => current.includes(a));

  return (
    <IonList style={{ paddingTop: 0, paddingBottom: 0 }}>
      <IonItemDivider mode="md" sticky={true}>
        <IonIcon icon={permissionsIcon} slot="start" />
        <IonLabel>
          <h2>Permissions</h2>
        </IonLabel>
      </IonItemDivider>

      {availableActionGroups.map((group, i) => (
        <IonItem key={i} lines={i === availableActionGroups.length - 1 ? 'full' : undefined}>
          <IonLabel>
            <h3>{group.name}</h3>
            <p>{group.subtitle}</p>
          </IonLabel>
          <IonToggle
            disabled={disabled}
            slot="end"
            onIonChange={(e) => onToggle(e, group.actions)}
            checked={isChecked(group.actions)}
          />
        </IonItem>
      ))}

      <IonLoading isOpen={mutating} />
    </IonList>
  );
};

export default ActionManager;
