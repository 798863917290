import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { Plugins } from '@capacitor/core';
import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonNote,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import BackButton from 'components/BackButton';
import ListSeparator from 'components/ListSeparator';
import PullToRefresh from 'components/PullToRefresh';
import { TableDivider, TableHeaderItem, TableItem } from 'components/TaskTable';
import UnsafeArea from 'components/UnsafeArea';
import AuthorizeRequired, { usePermissions } from 'containers/AuthorizeRequired';
import { parseAddress } from 'hooks/useLocation';
import { useMessages } from 'hooks/useMessages';
import { useWorkspace } from 'hooks/useWorkspace';
import { AccountAction } from 'interfaces/AccountAction';
import {
  carOutline as equipmentIcon,
  cashOutline as fixedCostItemIcon,
  cubeOutline as materialIcon,
  peopleOutline as laborIcon,
} from 'ionicons/icons';
import isEmpty from 'lodash.isempty';
import moment from 'moment';
import {
  ADD_MULTIPLE_TASK_EQUIPMENT,
  ADD_MULTIPLE_TASK_FIXED_COST_ITEM,
  ADD_MULTIPLE_TASK_LABOR,
  ADD_MULTIPLE_TASK_MATERIAL,
  AddMultipleTaskFixedCostItemInput,
  AddMultipleTaskLaborInput,
  EDIT_TASK,
  GET_AVAILABLE_ACCOUNTS,
  GET_ORGANIZATION_EQUIPMENT,
  GET_TASK,
  GetAvailableAccountsResponse,
  GetAvailableAccountsVariables,
  GetOrganizationEquipmentResponse,
  GetTaskResponse,
  REMOVE_TASK_EQUIPMENT,
  REMOVE_TASK_FIXED_COST_ITEM,
  REMOVE_TASK_LABOR,
  REMOVE_TASK_MATERIAL,
  RemoveTaskEquipmentInput,
  RemoveTaskFixedCostItemInput,
  RemoveTaskLaborInput,
  RemoveTaskMaterialInput,
} from 'pages/WorkLog/graphql';
import React, { useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useParams } from 'react-router-dom';
import { formatCurrency } from 'utils/formatCurrency';

import EquipmentModal, { EquipmentModalProps } from './components/AddEquipmentModal';
import FixedCostItemModal, { FixedCostItemModalProps } from './components/AddFixedCostItemModal';
import LaborModal, { LaborModalProps } from './components/AddLaborModal';
import MaterialModal, { MaterialModalProps } from './components/AddMaterialModal';
import EditTaskModal, { EditTaskModalProps } from './components/EditTaskModal/EditTaskModal';

const { Browser } = Plugins;

const useStyles = createUseStyles({
  header: {
    '--padding-top': 'var(--ion-margin, 16px)',
    '--padding-bottom': 'var(--ion-margin, 16px)',
    '--ion-grid-padding': 0,
    '& ion-grid': {
      paddingBottom: 'calc(var(--ion-margin, 16px) * 0.5)',
      borderBottom:
        'dashed 1px var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))))',
    },
  },
});

/**
 * List worklog task properties including individual labor, equipment, and material items.
 * Add new labor, equipment, and material items to the task.
 */
const TaskDetails: React.FC = () => {
  const { id: taskId } = useParams<{ id: string }>();

  const classes = useStyles();

  const { addMessage } = useMessages();

  const pageRef = useRef<HTMLElement>(null); // Bind to modals for card style display
  const contentRef = useRef<HTMLIonContentElement>(null); // Support for scroll to top

  const { workspace } = useWorkspace();

  const [removeLabor] = useMutation(REMOVE_TASK_LABOR);
  const [removeEquipment] = useMutation(REMOVE_TASK_EQUIPMENT);
  const [removeMaterial] = useMutation(REMOVE_TASK_MATERIAL);
  const [logLabor] = useMutation<unknown, AddMultipleTaskLaborInput>(ADD_MULTIPLE_TASK_LABOR);
  const [logEquipment] = useMutation(ADD_MULTIPLE_TASK_EQUIPMENT);
  const [logMaterial] = useMutation(ADD_MULTIPLE_TASK_MATERIAL);
  const [removeFixedCostItem] = useMutation(REMOVE_TASK_FIXED_COST_ITEM);
  const [logFixedCostItem] = useMutation<unknown, AddMultipleTaskFixedCostItemInput>(
    ADD_MULTIPLE_TASK_FIXED_COST_ITEM
  );
  const [editTask] = useMutation(EDIT_TASK);

  // Query task
  const { data, refetch } = useQuery<GetTaskResponse>(GET_TASK, {
    displayName: 'TaskDetails',
    variables: { taskId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    partialRefetch: true,
    onError: () => addMessage('Unable to load task.', 'danger'),
  });
  const task = data?.worklogTask;
  const safeFixedCostItemCost = task?.fixedCostItemCost ?? 0;
  const safeFixedCostItems = task?.fixedCostItems ?? [];
  const isInvoiced = task?.invoice?.id !== undefined;

  const [availableLaborAccounts, setAvailableLaborAccounts] = useState<
    LaborModalProps['availableAccounts']
  >([]);
  const [availableEquipment, setAvailableEquipment] = useState<
    EquipmentModalProps['availableEquipment']
  >([]);

  useQuery<GetAvailableAccountsResponse, GetAvailableAccountsVariables>(GET_AVAILABLE_ACCOUNTS, {
    displayName: 'TaskDetails',
    variables: { organizationId: workspace.organizationId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    partialRefetch: true,
    skip: !task,
    onCompleted: (response) => {
      const accounts = response.accounts.nodes.map((account) => ({
        id: account.id,
        name: account.name,
        defaultRate: account.rate,
        previouslyUsed: !!(task?.laborItems || []).find((item) => item.account.id === account.id),
        status: account.status,
      }));
      setAvailableLaborAccounts(accounts.filter((account) => account.status !== 'Deactivated'));
    },
    onError: () => addMessage('Unable to load available accounts.', 'danger'),
  });

  // Query equipment
  useQuery<GetOrganizationEquipmentResponse>(GET_ORGANIZATION_EQUIPMENT, {
    displayName: 'TaskDetails',
    variables: { organizationId: workspace.organizationId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    partialRefetch: true,
    onCompleted: (response) => {
      setAvailableEquipment(
        response.organization.equipment.filter((equipment) => equipment.isActive === true) || []
      );
    },
    onError: () => addMessage('Unable to load available equipment.', 'danger'),
  });

  // Authorization
  const isAuthorizedLaborItemsDelete = usePermissions({
    required: [AccountAction.RemoveWorklogTaskLabor],
  });
  const isAuthorizedEquipmentItemsDelete = usePermissions({
    required: [AccountAction.RemoveWorklogTaskEquipment],
  });
  const isAuthorizedMaterialItemsDelete = usePermissions({
    required: [AccountAction.RemoveWorklogTaskMaterial],
  });
  const isAuthorizedFixedCostItemsDelete = usePermissions({
    required: [AccountAction.RemoveWorklogTaskFixedCostItem],
  });

  const onDeleteLabor = async (variables: RemoveTaskLaborInput) => {
    try {
      await removeLabor({ variables });

      // Account should now be flagged as not previously used
      setAvailableLaborAccounts((prev) =>
        prev.map((account) => ({
          ...account,
          previouslyUsed: account.id === variables.accountId ? false : account.previouslyUsed,
        }))
      );
    } catch (err) {
      const { graphQLErrors } = err as ApolloError;
      const messages = [...(graphQLErrors || [])].map((err) => err.message);
      addMessage(`${['Unable to delete labor', ...messages].join('. ')}.`, 'danger');
    }
  };

  const onDeleteEquipment = async (variables: RemoveTaskEquipmentInput) => {
    try {
      await removeEquipment({ variables });
    } catch (err) {
      const { graphQLErrors } = err as ApolloError;
      const messages = [...(graphQLErrors || [])].map((err) => err.message);
      addMessage(`${['Unable to delete equipment', ...messages].join('. ')}.`, 'danger');
    }
  };

  const onDeleteMaterial = async (variables: RemoveTaskMaterialInput) => {
    try {
      await removeMaterial({ variables });
    } catch (err) {
      const { graphQLErrors } = err as ApolloError;
      const messages = [...(graphQLErrors || [])].map((err) => err.message);
      addMessage(`${['Unable to delete item', ...messages].join('. ')}.`, 'danger');
    }
  };

  const onDeleteFixedCostItem = async (variables: RemoveTaskFixedCostItemInput) => {
    try {
      await removeFixedCostItem({ variables });
    } catch (err) {
      const { graphQLErrors } = err as ApolloError;
      const messages = [...(graphQLErrors || [])].map((err) => err.message);
      addMessage(`${['Unable to delete item', ...messages].join('. ')}.`, 'danger');
    }
  };

  const onSubmitLabor: LaborModalProps['onSubmit'] = async (labors) => {
    if (isEmpty(labors)) return;

    try {
      await logLabor({ variables: { taskId, labors } });

      setAvailableLaborAccounts((prev) =>
        prev.map((account) => {
          // if account is in labors, set as previously used
          const isPreviouslyUsed = labors.some((labor) => labor.accountId === account.id);
          return {
            ...account,
            previouslyUsed: isPreviouslyUsed ? true : account.previouslyUsed,
          };
        })
      );
    } catch (err) {
      const { graphQLErrors } = err as ApolloError;
      const messages = [...(graphQLErrors || [])].map((err) => err.message);
      addMessage(`${['Unable to add labor', ...messages].join('. ')}.`, 'danger');
      throw err;
    }
  };

  const onSubmitEquipment: EquipmentModalProps['onSubmit'] = async (equipments) => {
    if (isEmpty(equipments)) return;

    try {
      await logEquipment({ variables: { taskId, equipments } });
    } catch (err) {
      const { graphQLErrors } = err as ApolloError;
      const messages = [...(graphQLErrors || [])].map((err) => err.message);
      addMessage(`${['Unable to add equipment', ...messages].join('. ')}.`, 'danger');
      throw err;
    }
  };

  const onSubmitMaterial: MaterialModalProps['onSubmit'] = async (materials) => {
    if (isEmpty(materials)) return;

    try {
      await logMaterial({ variables: { taskId, materials } });
    } catch (err) {
      const { graphQLErrors } = err as ApolloError;
      const messages = [...(graphQLErrors || [])].map((err) => err.message);
      addMessage(`${['Unable to add item', ...messages].join('. ')}.`, 'danger');
      throw err;
    }
  };

  const onSubmitFixedCostItem: FixedCostItemModalProps['onSubmit'] = async (fixedCostItems) => {
    if (isEmpty(fixedCostItems)) return;

    try {
      await logFixedCostItem({ variables: { taskId, fixedCostItems } });
    } catch (err) {
      const { graphQLErrors } = err as ApolloError;
      const messages = [...(graphQLErrors || [])].map((err) => err.message);
      addMessage(`${['Unable to add item', ...messages].join('. ')}.`, 'danger');
      throw err;
    }
  };

  // call mutation to update task
  const onSubmitEditTask: EditTaskModalProps['onSubmit'] = async (taskItems) => {
    if (isEmpty(taskItems)) return;
    const { title: name, taskDate } = taskItems;

    try {
      await editTask({ variables: { taskId, name, taskDate } });
    } catch (err) {
      const { graphQLErrors } = err as ApolloError;
      const messages = [...(graphQLErrors || [])].map((err) => err.message);
      addMessage(`${['Unable to edit task', ...messages].join('. ')}.`, 'danger');
      throw err;
    }
  };

  const scrollToTop = () => contentRef.current && contentRef.current.scrollToTop();

  return (
    <IonPage ref={pageRef}>
      <IonHeader>
        <IonToolbar onClick={scrollToTop}>
          <IonButtons slot="start">
            {task ? (
              <BackButton defaultHref={`/worklog/${task.workorder.id}`} />
            ) : (
              <IonMenuButton />
            )}
          </IonButtons>
          <IonTitle>{task?.name}</IonTitle>
          <AuthorizeRequired required={[AccountAction.EditWorklogTask]}>
            <EditTaskModal
              pageRef={pageRef}
              taskName={task?.name}
              currTaskDate={task?.taskDate}
              onSubmit={onSubmitEditTask}
            />
          </AuthorizeRequired>
        </IonToolbar>
      </IonHeader>

      <IonContent ref={contentRef} style={{ '--padding-bottom': 'var(--ion-margin, 16px)' }}>
        <PullToRefresh onRefresh={refetch} />

        {task && (
          <>
            <IonItem className={classes.header} lines="full">
              <div style={{ flex: '1 1 0%' }}>
                <IonGrid>
                  <IonRow className="ion-align-items-baseline">
                    {task.workorder.location?.address && (
                      <IonCol style={{ paddingLeft: 0, paddingRight: 'var(--ion-margin, 16px)' }}>
                        <IonLabel>
                          <h3>{parseAddress(task.workorder.location?.address)}</h3>
                        </IonLabel>
                      </IonCol>
                    )}
                    <IonCol size="auto" style={{ paddingRight: 0, paddingLeft: 0 }}>
                      <IonLabel>
                        <h3>{moment(task.taskDate).format('MMM D, YYYY')}</h3>
                      </IonLabel>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <div className="ion-margin-top">
                  <IonLabel color="secondary">
                    <h2>{formatCurrency(task.totalCost)}</h2>
                  </IonLabel>
                </div>
              </div>
            </IonItem>

            <ListSeparator />

            <IonList className="ion-no-padding" lines="full">
              <IonItem routerLink={`/workorders/details/${task.workorder.id}`}>
                <IonLabel>
                  <p>
                    <IonText color="dark">Work Order</IonText> {task.workorder.title}
                  </p>
                </IonLabel>
              </IonItem>
              {isInvoiced && (
                <IonItem
                  button={task.invoice.url ? true : false}
                  onClick={() => {
                    if (!task.invoice.url) return;
                    Browser.open({ url: task.invoice.url });
                  }}
                >
                  <IonLabel>
                    <p>
                      <IonText color="dark">Invoice</IonText> {task.invoice.invoiceNumber}
                    </p>
                  </IonLabel>
                </IonItem>
              )}
            </IonList>

            {isInvoiced && (
              <div className="ion-padding" style={{ fontSize: '0.875rem', lineHeight: 1.6 }}>
                <IonNote color="medium">
                  <p className="ion-no-margin">
                    Labor, equipment, materials, and fixed cost items cannot be added after a task
                    has been invoiced
                  </p>
                </IonNote>
              </div>
            )}

            <ListSeparator />

            <IonList style={{ paddingTop: 0, paddingBottom: 0 }}>
              <TableDivider icon={laborIcon} title="Labor" itemCost={task.laborCost} />
              {task.laborItems.length > 0 && <TableHeaderItem quantityLabel="Hours" />}
              {task.laborItems.map(({ account: accountI, rate, hours, itemCost }, index) => (
                <TableItem
                  key={index}
                  lines={index === task.laborItems.length - 1 ? 'full' : 'inset'}
                  name={accountI.name}
                  rate={rate}
                  quantity={hours}
                  total={itemCost}
                  deleteAllowed={isAuthorizedLaborItemsDelete}
                  deleteHeader="Would you like to permanently delete this labor item?"
                  onDelete={onDeleteLabor.bind(null, {
                    taskId: task.id,
                    accountId: accountI.id,
                  })}
                />
              ))}
              {!isInvoiced && (
                <AuthorizeRequired required={[AccountAction.AddMultipleWorklogTaskLabor]}>
                  <LaborModal
                    pageRef={pageRef}
                    availableAccounts={availableLaborAccounts}
                    keys={['name']}
                    onSubmit={onSubmitLabor}
                  />
                </AuthorizeRequired>
              )}

              <ListSeparator />

              {/* Equipment */}
              <TableDivider icon={equipmentIcon} title="Equipment" itemCost={task.equipmentCost} />
              {task.equipmentItems.length > 0 && <TableHeaderItem quantityLabel="Hours" />}
              {task.equipmentItems.map(
                ({ equipment: equip, rate, hours, itemCost, mileage }, index) => (
                  <TableItem
                    key={index}
                    lines={index === task.equipmentItems.length - 1 ? 'full' : 'inset'}
                    name={equip.name}
                    rate={rate}
                    quantity={hours}
                    note={mileage ? `Mileage: ${mileage}` : undefined}
                    total={itemCost}
                    deleteAllowed={isAuthorizedEquipmentItemsDelete}
                    deleteHeader="Would you like to permanently delete this equipment item?"
                    onDelete={onDeleteEquipment.bind(null, {
                      taskId: task.id,
                      equipmentId: equip.id,
                    })}
                  />
                )
              )}
              {!isInvoiced && (
                <AuthorizeRequired required={[AccountAction.AddMultipleWorklogTaskEquipment]}>
                  <EquipmentModal
                    pageRef={pageRef}
                    availableEquipment={availableEquipment}
                    previouslyUsed={task.equipmentItems}
                    keys={['name']}
                    onSubmit={onSubmitEquipment}
                  />
                </AuthorizeRequired>
              )}

              <ListSeparator />

              {/* Materials */}
              <TableDivider icon={materialIcon} title="Materials" itemCost={task.materialCost} />
              {task.materialItems.length > 0 && (
                <TableHeaderItem rateLabel="Unit Cost" quantityLabel="Quantity" />
              )}
              {task.materialItems.map(
                (
                  { id, name: materialName, unitCost, quantity, itemCost, referenceNumber },
                  index
                ) => (
                  <TableItem
                    key={index}
                    lines={index === task.materialItems.length - 1 ? 'full' : 'inset'}
                    name={materialName}
                    rate={unitCost}
                    quantity={quantity}
                    total={itemCost}
                    note={referenceNumber || undefined}
                    deleteAllowed={!!id && isAuthorizedMaterialItemsDelete}
                    deleteHeader="Would you like to permanently delete this item?"
                    onDelete={
                      !!id
                        ? onDeleteMaterial.bind(null, {
                            taskId: task.id,
                            materialId: id,
                          })
                        : () => undefined
                    }
                  />
                )
              )}
              {!isInvoiced && (
                <AuthorizeRequired required={[AccountAction.AddMultipleWorklogTaskMaterial]}>
                  <MaterialModal pageRef={pageRef} onSubmit={onSubmitMaterial} />
                </AuthorizeRequired>
              )}

              <ListSeparator />

              {/* Fixed cost items */}
              <TableDivider
                icon={fixedCostItemIcon}
                title="Fixed Cost Items"
                itemCost={safeFixedCostItemCost}
              />
              {safeFixedCostItems.length > 0 && (
                <TableHeaderItem rateLabel="Unit Cost" quantityLabel="Quantity" unitLabel="Unit" />
              )}
              {safeFixedCostItems.map(
                ({ id, name, unitCost, quantity, itemCost, unit }, index) => (
                  <TableItem
                    key={index}
                    lines={index === safeFixedCostItems.length - 1 ? 'full' : 'inset'}
                    name={name}
                    rate={unitCost}
                    quantity={quantity}
                    total={itemCost}
                    unit={unit || ' '}
                    deleteAllowed={!!id && isAuthorizedFixedCostItemsDelete}
                    deleteHeader="Would you like to permanently delete this item?"
                    onDelete={
                      !!id
                        ? onDeleteFixedCostItem.bind(null, {
                            taskId: task.id,
                            fixedCostItemId: id,
                          })
                        : () => undefined
                    }
                  />
                )
              )}
              {!isInvoiced && (
                <AuthorizeRequired required={[AccountAction.AddMultipleWorklogTaskFixedCostItem]}>
                  <FixedCostItemModal pageRef={pageRef} onSubmit={onSubmitFixedCostItem} />
                </AuthorizeRequired>
              )}
            </IonList>

            <UnsafeArea position="bottom" />
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default TaskDetails;
